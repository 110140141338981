/* Genel Karanlık Tema */
.dark {
	background-color: #121212;
	color: #f5f5f5;
}

.mainScreen {
	padding: 10px;
	font-family: 'Roboto', sans-serif;
	padding-top: 275px;
}
.main-logout-button {
	position: absolute;
	top: 50px;
	left: 20px;
	transform: translateY(-50%);
	width: 40px;
	height: 40px;
	background-color: #000000;
	border: none;
	border-radius: 20%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color 0.3s ease;
}
.main-logout-button:hover {
	background-color: #d00c0c;
}

.mainScreen-container {
	max-width: 900px;
	margin: 0 auto;
	background: #282828;
	border-radius: 8px;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
	padding: 40px;
	display: flex; /* Flexbox ile daha esnek düzen */
	flex-direction: column; /* Dikey yönde yerleşim */
	align-items: center; /* Ortalamak için */
	height: auto; /* Yüksekliği otomatik ayarla */
	min-height: 80vh; /* Minimum yükseklik belirle */
	margin-bottom: 50px;
}
.mainScreen-rankking {
	max-width: 650px;
	width: 100%;
	cursor: pointer;
	transition: max-width 0.3s ease; /* Smooth transition for max-width */
}

.mainScreen-rankking:hover {
	max-width: 680px;
}

.mainScreen-header-container {
	border-bottom: 1px solid #494949; /* Sadece alt kenar için sınır */
	display: flex;
	text-align: center;
	margin-bottom: 30px;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%; /* Tam genişlik */
	padding-bottom: 30px;
}

.mainScreen-header-title {
	text-align: center;
	font-size: 2rem;
	color: #f5f5f5;
	margin-bottom: 10px; /* Daha az boşluk */
}

.mainScreen-header-subtitle {
	text-align: center;
	font-size: 1rem;
	color: #cccccc;
	width: 100%; /* Mobilde tam genişlik */
	max-width: 500px; /* Maksimum genişlik */
	padding: 0 10px; /* Yan boşluk ekle */
}

.mainScreen-video {
	display: flex;
	justify-content: center;
	width: 100%; /* Tam genişlik */
	height: auto; /* Yüksekliği otomatik ayarla */
	margin: 10px 0;
	padding: 10px; /* Video etrafına biraz boşluk ekle */
}

.mainScreen-video > div {
	width: 100%; /* Video alanının genişliğini ayarla */
	position: relative;
}

.mainScreen-video > div > iframe {
	position: absolute; /* Pozisyonu ayarla */
	top: 0;
	left: 0;
	width: 100%; /* Tam genişlik */
	height: 100%; /* Tam yükseklik */
	display: flex;
}

.mainScreen-section-title {
	font-size: 1.3rem;
	color: #007bff;
	cursor: pointer;
	transition: color 0.3s;
	text-align: left;
}

.mainScreen-section-title:hover {
	color: #66a2ff;
}

.mainScreen-content {
	text-align: left;
	margin: 20px 0;
	font-size: 1.1rem;
	color: #f5f5f5;
	padding: 0 10px; /* Yan boşluk ekle */
}

.mainScreen-button-section {
	display: flex;
	justify-content: center;
	gap: 20px;
	margin-top: 20px;
	flex-wrap: wrap; /* Düğmeleri sarmalayıcı bir düzenle göster */
}

.mainScreen-settings-button,
.mainScreen-help-button {
	padding: 10px 20px;
	font-size: 1rem;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s;
	width: 100%; /* Tam genişlik */
	max-width: 200px; /* Maksimum genişlik */
}

.mainScreen-settings-button {
	background-color: #28a745;
	color: white;
}

.mainScreen-settings-button:hover {
	background-color: #218838;
}

.mainScreen-help-button {
	background-color: #007bff;
	color: white;
}

.mainScreen-help-button:hover {
	background-color: #0056b3;
}

.video-try {
	border-radius: 20%;
}

/* Responsive Design */
@media (max-width: 768px) {
	.mainScreen-header-title {
		font-size: 1.5rem; /* Mobilde daha küçük başlık */
	}

	.mainScreen-header-subtitle {
		font-size: 0.9rem; /* Mobilde daha küçük alt başlık */
	}

	.mainScreen-section-title {
		font-size: 1.2rem; /* Mobilde daha küçük bölüm başlığı */
	}
}
