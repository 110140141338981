body,
html {
	flex: 100;
	height: 100%;
	width: 100%;
	margin: 0;
	background-color: #000000;
}

.login-main {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 100vh; /* Ekran yüksekliğini tam kaplasın */
	margin-top: 130px;
}

.login-screen {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%; /* Ekran genişliğini tam kaplasın */
}

.login-section {
	flex: 1;
	display: flex;
	flex-direction: column; /* Dikey sıralama */
	justify-content: center; /* Dikeyde ortalama */
	align-items: center; /* Yatayda ortalama */
	padding: 90px; /* Daha az padding */
	border-radius: 16px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	background-color: #191919;
	text-align: center;
	max-width: 400px; /* Çok genişlememesini sağla */
}

.error-message {
	color: white;
	font-size: 18px;
	margin-top: 10px;
	text-align: center;
	width: 100%;
}

.login-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-right: 30px;
}

.login-title {
	font-size: 31px;
	font-weight: bold;
	margin-bottom: 20px;
	color: white;
	text-align: center;
}

.login-form input {
	width: 80%;
	height: 30px;
	margin-bottom: 15px;
	border: 1px solid #333639;
	border-radius: 4px;
	font-size: 15px;
	background-color: #191919;
	color: white;
	padding: 10px;
	transition: background-color 0.3s ease;
}

.login-form input:valid {
	background-color: #333639; /* Change the background color when input is valid */
}

.login-form input::placeholder {
	color: #999; /* Placeholder color */
}

.login-form input:focus {
	background-color: #222; /* Optional: Change background on focus */
	box-shadow: 0 0 5px rgba(29, 161, 242, 0.5); /* Subtle glow on focus */
}

.login-form button {
	width: 87%;
	padding: 12px;
	background-color: #1da1f2;
	color: white;
	border: none;
	border-radius: 50px;
	font-size: 15px;
	font-weight: bold;
	cursor: pointer;
	transition: background-color 0.2s;
}

.login-form button:hover {
	background-color: #1a91da;
}

.login-logo {
	border-radius: 50%;
	width: 300px;
	height: 300px;
	margin-right: 100px;
}

.login-footer {
	flex: 1;
	display: flex;
	flex-direction: row;
	position: absolute;
	bottom: 30px;
	justify-content: space-around;
	gap: 20px; /* Add gap between text in login-footer */
	margin-top: 100px;
}

.toggle-login-register {
	font-size: 14px;
	color: #1da1f2;
	cursor: pointer;
	transition: color 0.2s;
}

.toggle-login-register:hover {
	color: #1a91da;
}

.email-login-form {
	gap: 10px;
	align-items: center;
	justify-content: center;
}

.twitter-login-button {
	width: 100%;
	padding: 12px;
	background-color: #1da1f2;
	color: white;
	border: none;
	border-radius: 50px;
	font-size: 15px;
	font-weight: bold;
	gap: 10px;
	margin-top: 10px;
}

.error-message-login {
	color: #ff4d4f; /* Daha yumuşak kırmızı */
	background-color: rgba(255, 77, 79, 0.1); /* Hafif kırmızı arka plan */
	border: 1px solid #ff4d4f; /* Aynı kırmızı tonunda kenarlık */
	border-radius: 8px; /* Yuvarlak kenarlıklar */
	padding: 10px 20px; /* İçerik ile kenarlar arasında boşluk */
	margin-top: 15px; /* Üstten biraz boşluk */
	font-size: 16px; /* Hata mesajı için okunabilir boyut */
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.error-message-login::before {
	content: '❌'; /* Hata simgesi */
	font-size: 20px;
	margin-right: 10px; /* Simge ile metin arasında boşluk */
}

.forgot-password {
	color: #1da1f2; /* Twitter mavisi renk */
	font-size: 14px; /* Yazı boyutu */
	cursor: pointer; /* Fare ile üzerine gelince pointer yap */
	margin-top: 10px; /* Üstten boşluk */
	transition: color 0.2s ease-in-out; /* Renk değişimi geçişi */
	text-decoration: underline; /* Alt çizgi */
}

.forgot-password:hover {
	color: #1a91da; /* Üzerine gelince rengini değiştir */
}

.success-message-login {
	color: #4caf50; /* Başarı yeşili */
	background-color: rgba(76, 175, 80, 0.1); /* Hafif yeşil arka plan */
	border: 1px solid #4caf50; /* Aynı yeşil tonunda kenarlık */
	border-radius: 8px; /* Yuvarlak kenarlıklar */
	padding: 10px 20px; /* İçerik ile kenarlar arasında boşluk */
	margin-top: 15px; /* Üstten biraz boşluk */
	font-size: 16px; /* Başarı mesajı için okunabilir boyut */
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.success-message-login::before {
	content: '✔️'; /* Başarı simgesi */
	font-size: 20px;
	margin-right: 10px; /* Simge ile metin arasında boşluk */
}

.login-footer a,
.login-footer p {
	font-size: 14px;
	color: #1da1f2;
	cursor: pointer;
	transition: color 0.2s;
	text-decoration: none;
	margin: 0; /* Varsayılan margin değerlerini sıfırlar */
	padding: 0; /* Varsayılan padding değerlerini sıfırlar */
}

.login-footer a:hover,
.login-footer p:hover {
	color: #1a91da;
	text-decoration: underline;
}

@media (max-width: 1074px) {
	.login-screen {
		flex-direction: column;
	}
	.login-section {
		padding: 50px;
	}
	.login-container {
		padding: 0px;
	}

	.login-logo {
		margin-right: 0; /* Küçük ekranlar için margin-right'ı sıfırla */
		margin-bottom: 30px;

		width: 200px;
		height: 200px;
	}
	.login-section {
		height: auto; /* Sabit yükseklik yerine otomatik ayarla */
		margin-top: 20px; /* Üstten biraz boşluk */
		padding: 30px 60px;
	}

	.login-title {
		font-size: 20px;
	}

	.login-form input,
	.login-form button {
		padding: 8px;
		font-size: 12px;
	}
	.error-message-login {
		margin: 20px;
	}
}

@media (max-width: 722px) {
	.login-section,
	.login-container {
		padding: 5px;
	}

	.login-logo {
		margin-right: 0; /* Küçük ekranlar için margin-right'ı sıfırla */
		margin-bottom: 30px;

		width: 150px;
		height: 150px;
	}
	.login-section {
		height: auto; /* Sabit yükseklik yerine otomatik ayarla */
		margin-top: 20px; /* Üstten biraz boşluk */
		padding: 50px 0;
	}

	.login-title {
		font-size: 20px;
	}

	.login-form input,
	.login-form button {
		padding: 8px;
		font-size: 12px;
	}
	.error-message-login {
		margin: 20px;
	}
}
@media (max-width: 500px) {
	.login-section {
		margin: 15px;
	}

	.login-logo {
		margin-right: 0; /* Küçük ekranlar için margin-right'ı sıfırla */
		margin-bottom: 30px;
		width: 125px;
		height: 125px;
	}
	.login-section {
		height: auto; /* Sabit yükseklik yerine otomatik ayarla */
		margin-top: 20px; /* Üstten biraz boşluk */
		padding: 50px 0;
	}

	.login-title {
		font-size: 20px;
	}

	.login-form input,
	.login-form button {
		padding: 8px;
		font-size: 12px;
	}
	.error-message-login {
		margin: 20px;
	}

	.login-footer {
		position: relative;
	}
}
@media (max-height: 730px) {
	.login-section {
		margin-top: 150px;
	}
}
