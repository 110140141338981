.reset-password-main {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 100vh;
	padding: 20px;
}

.reset-password-screen {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.reset-password-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 90px;
	border-radius: 16px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	background-color: #191919;
	text-align: center;
	max-width: 400px;
}

.reset-password-title {
	font-size: 31px;
	font-weight: bold;
	margin-bottom: 20px;
	color: white;
	text-align: center;
}

.input {
	width: 80%;
	height: 30px;
	margin-bottom: 15px;
	border: 1px solid #333639;
	border-radius: 4px;
	font-size: 15px;
	background-color: #191919;
	color: white;
	padding: 10px;
	transition: background-color 0.3s ease;
}

.input::placeholder {
	color: #999;
}

.input:focus {
	background-color: #222;
	box-shadow: 0 0 5px rgba(29, 161, 242, 0.5);
}

.reset-button {
	width: 87%;
	padding: 12px;
	background-color: #1da1f2;
	color: white;
	border: none;
	border-radius: 50px;
	font-size: 15px;
	font-weight: bold;
	cursor: pointer;
	transition: background-color 0.2s;
}

.reset-button:hover {
	background-color: #1a91da;
}

.error-message {
	color: #ff4d4f;
	font-size: 16px;
	margin-top: 10px;
	text-align: center;
}

.success-message {
	color: white;
	font-size: 16px;
	margin-top: 10px;
	padding: 20px;
	text-align: center;
}

@media (max-width: 1074px) {
	.reset-password-container {
		padding: 20px;
	}

	.reset-password-title {
		font-size: 24px;
	}

	.input,
	.reset-button {
		padding: 10px;
		font-size: 14px;
	}
}

@media (max-width: 722px) {
	.reset-password-container {
		padding: 5px;
	}

	.reset-password-title {
		font-size: 20px;
	}

	.input,
	.reset-button {
		padding: 8px;
		font-size: 12px;
	}
}

@media (max-width: 500px) {
	.reset-password-container {
		margin: 15px;
	}

	.reset-password-title {
		font-size: 20px;
	}

	.input,
	.reset-button {
		padding: 8px;
		font-size: 12px;
	}
}
