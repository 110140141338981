/* Genel Stil */
.privacy-section {
	background-color: white;
	padding: 20px 50px; /* Masaüstü cihazlar için varsayılan padding */
	margin: 20px auto; /* Ortalamak için margin auto */
	max-width: 800px; /* İçeriğin genişliğini sınırlandırmak */
	border-radius: 10px; /* Hafif köşe yuvarlaması */
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Hafif gölge efekti */
}

/* Tablet ve daha küçük ekranlar için stil */
@media (max-width: 1000px) {
	.privacy-section {
		padding: 15px 30px; /* Tablet ekranları için daha küçük padding */
		margin: 20px 50px; /* Marginleri azaltmak */
	}
}

.header-text {
	text-align: center;
	align-items: center;
}
.header-text p {
	color: white;
	font-weight: bold;
	font-size: 40px;
}
