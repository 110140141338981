/* TeamPointsTable.css */

.table-container {
	width: 80%;
	margin: auto;
	margin-top: 20px;
}

.table-title {
	text-align: center;
	margin-bottom: 20px;
	font-size: 24px;
	color: #ffffff;
}

.season-select {
	margin-bottom: 20px;
	padding: 10px;
	font-size: 16px;
	width: 100%;
	max-width: 300px;
	display: block;
	margin: 0 auto;
}

.table-wrapper {
	overflow-x: auto;
	max-width: 100%;
}

.table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 20px;
}

.table th,
.table td {
	border: 1px solid #ddd;
	padding: 12px;
	text-align: left;
}

.table th {
	background-color: #f4f4f4;
	font-weight: bold;
	color: #333;
}

.table tbody tr:hover {
	background-color: #808080;
}

.table td {
	color: #ffffff;
}

@media (max-width: 768px) {
	.table-container {
		width: 100%;
	}

	.season-select {
		width: 100%;
	}
}
