.footer {
	display: flex;
	flex-direction: row; /* Masaüstü cihazlarda öğeleri yan yana sıralar */
	justify-content: center;
	gap: 20px; /* Metinler arasında boşluk */
	padding-top: 20px;
	padding-bottom: 20px;
	color: white; /* Yazı rengi */
	width: 100%; /* Footer genişliği */
	flex-wrap: wrap; /* Küçük ekranlarda içeriğin taşmaması için içerikleri sarmalar */
}

.footer a,
.footer p {
	font-size: 14px;
	color: #1da1f2;
	cursor: pointer;
	transition: color 0.2s;
	text-decoration: none;
	margin: 0; /* Varsayılan margin değerlerini sıfırlar */
	padding: 0; /* Varsayılan padding değerlerini sıfırlar */
}

.footer a:hover,
.footer p:hover {
	color: #1a91da;
	text-decoration: underline;
}

/* Mobil cihazlar (480px ve daha küçük ekranlar) için stil */
@media (max-width: 480px) {
	.footer {
		flex-direction: column; /* Mobil cihazlarda öğeleri alt alta sıralar */
		align-items: center; /* Öğeleri ortalar */
		gap: 10px; /* Mobil cihazlar için öğeler arasındaki boşluk */
		padding: 10px 0; /* Mobil cihazlar için padding değerlerini küçült */
	}

	.footer a,
	.footer p {
		font-size: 12px; /* Yazı boyutunu küçült */
		margin: 5px 0; /* Metinler arasına daha küçük boşluklar ekle */
	}
}
