.main {
	flex: 100;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 20px;
	box-sizing: border-box;
	align-items: center;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
	padding-top: 275px;
}

/* UCL için mavi tonlarında bir gradient */
.main.ucl {
	background: radial-gradient(
		circle,
		rgba(6, 48, 137, 0.6) 0%,
		rgba(0, 0, 0, 1) 100%
	);
}
/* Stilize edilmiş ters K harfi için */
.reverse-k {
	display: inline-block;
	transform: scaleX(-1); /* Yatay eksende ters çevir */
}

/* UEL için turuncu tonlarında bir gradient */
.main.uel {
	background: radial-gradient(
		circle,
		rgba(240, 87, 22, 0.6) 0%,
		rgba(0, 0, 0, 1) 90%
	);
}

/* UECL için yeşil tonlarında bir gradient */
.main.uecl {
	background: radial-gradient(
		circle,
		rgba(0, 188, 18, 0.6) 0%,
		rgba(0, 0, 0, 1) 90%
	);
}

.logout-button,
.login-button {
	position: absolute;
	top: 50px;
	left: 20px;
	transform: translateY(-50%);
	width: 40px;
	height: 40px;
	background-color: #000000;
	border: none;
	border-radius: 20%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color 0.3s ease;
}

/* Default hover */
.logout-button:hover,
.login-button:hover {
	background-color: #0c85d0;
}

/* UCL hover rengi */
.ucl-logout-button:hover,
.ucl-login-button:hover {
	background-color: #063089;
}

/* UEL hover rengi */
.uel-logout-button:hover,
.uel-login-button:hover {
	background-color: #f05716;
}

/* UECL hover rengi */
.uecl-logout-button:hover,
.uecl-login-button:hover {
	background-color: #00bc12;
}

.main-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
	width: 100%;
	align-items: center;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%; /* Mobilde %100 olacak */
}
.no-scroll {
	overflow: hidden;
	height: 100%;
}
/* League Selection kısmı mobilde tek satırda duracak */
.league-selection {
	display: flex;
	justify-content: space-between;
	gap: 10px;
	width: 100%;
	margin-block: 15px;
}
/* League Selection kısmı mobilde tek satırda duracak */
.button-selection {
	display: flex;
	justify-content: space-around;
	gap: 10px;
	width: 100%;
	margin-block: 15px;
}

/* General styling for league titles */
.league-title {
	color: white;
	font-size: 18px;
	font-weight: bold;
	cursor: pointer;
	padding: 8px 16px;
	border-radius: 10px;
	background-color: #444;
	transition: background-color 0.3s ease;
	flex: 1;
	text-align: center;
	display: flex;
	flex-direction: column; /* Stack league name and Teams vertically */
	justify-content: center;
	align-items: center;
}

/* On smaller screens (below 500px), change the layout */
@media (max-width: 500px) {
	.league-title {
		font-size: 16px;
		padding: 8px;
	}
	.league-title span {
		display: block;
	}
}

/* Active states for UCL, UEL, and UECL */
.league-title.active.ucl {
	background-color: #063089;
	color: white;
}

.league-title.active.uel {
	background-color: #f05716;
	color: white;
}

.league-title.active.uecl {
	background-color: #00bc12;
	color: white;
}

.league-title:hover {
	background-color: #555;
}

/* Grid ve Sonuç Listesi */
.grid-section {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.grid-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
}

.grid-container > h2 {
	color: white;
	font-size: 20px;
	margin-bottom: 10px;
}

.result {
	background-color: #282828;
	border-radius: 15px;
	padding: 20px;
	color: white;
	width: 100%; /* Mobilde %100 genişlik */
	box-sizing: border-box;
	overflow-y: auto;
}

.result ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.result li {
	background-color: #444;
	margin: 5px 0;
	padding: 10px;
	border-radius: 8px;
	color: wheat;
	font-size: 18px;
	font-weight: bold;
	text-align: left;
	transition: background-color 0.3s ease;
	cursor: pointer;
	position: relative;
	padding-left: 30px;
}

.result li::before {
	counter-increment: item;
	position: absolute;
	left: 10px;
	color: #aaa;
	font-size: 14px;
}

.result li:hover {
	background-color: #555;
}

.clear-button {
	background-color: #f21d1d;
	margin-bottom: 15px;
}
.clear-button p,
.submit-button p,
.update-button p {
	text-align: center;
	display: inline;
	font-weight: bold;
}
.submit-button {
	background-color: #1da1f2;
	margin-bottom: 15px;
}

.submit-button,
.clear-button,
.update-button {
	width: 300px; /* Butonlar %100 genişlikte olacak */
	max-width: 150px; /* Ama en fazla 150px genişliğinde olacak */
	height: 40px;
	border: none;
	color: white;
	font-size: 16px;
	font-weight: bold;
	border-radius: 20px;
	cursor: pointer;
	transition: background-color 0.6s ease;
}

.submit-button:hover {
	background-color: #0c85d0;
}
.clear-button:hover {
	background-color: #b40a0a;
}

/* Yeşil arka plan */

/* header.css */
.header-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #282828;
	border-radius: 10px;
	width: 100%;
	flex-direction: column;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.header-title {
	color: #ff0000;
	font-size: 28px;
	font-weight: bold;
	text-align: center;
}

.language-selector {
	position: relative;
	display: inline-block;
}

.language-button {
	background: #28a745;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.language-button:hover {
	background-color: #218838;
}

.dropdown-menu {
	position: relative;
	top: 20; /* Position the dropdown below the button */
	right: 0;
	background-color: #ffffff;
	border-radius: 5px;
	border: none;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	z-index: 1000; /* Ensure it appears above other content */
	list-style: none;
	padding: 0;
}

.dropdown-menu li {
	padding: 8px 19px;
	cursor: pointer;
	text-align: center;
	color: #333;
}

.dropdown-menu li:hover {
	background-color: #f1f1f1;
	border-radius: 5px;
}

.header-subtitle {
	color: #c1c1c1;
	font-size: 18px;
	text-align: center;
}

.header-buttons {
	position: absolute; /* Sabit pozisyon */
	top: 30px; /* En üstte 10px mesafe */
	right: 20px; /* Sağdan 20px mesafe */
	display: flex;
	gap: 10px;
	justify-content: flex-end; /* Butonları sağa hizalayın */
}
.how-to-play-button,
.language-button,
.user-ranks-button {
	color: white;
	font-size: 18px;
	font-weight: 600;
	cursor: pointer;
	padding: 8px 16px;
	border-radius: 10px;
	background-color: #444;
	transition: background-color 0.3s ease;
	flex: 1;
	text-align: center;
	display: flex;
	flex-direction: column; /* Stack league name and Teams vertically */
	justify-content: center;
	align-items: center;
}

.how-to-play-button:hover,
.language-button:hover {
	background-color: #ff9100;
}

.language-button {
	background: #ff0000;
}
.how-to-play-button {
	background: #ffa600;
}

.language-button:hover {
	background-color: #d80000;
}
.user-ranks-button {
	background: #9900ff;
}

.user-ranks-button:hover {
	background-color: #7703c5;
}

.message {
	padding: 15px;
	border-radius: 5px;
	margin: 20px 0;
	width: 100%;
	max-width: 930px; /* Apply a max-width for consistency */
	text-align: center;
	font-size: 20px;
	box-sizing: border-box;
	background-color: #f44336; /* Red background */
}

/* Specific colors for each type */
.success-s {
	background-color: #28a745; /* Green background */
}

.warning-message {
	background-color: #ffc107; /* Yellow background */
}

.update-button {
	width: 300px; /* Buton %100 genişlikte olacak */
	max-width: 150px; /* En fazla 150px genişlik */
	height: 40px;
	background-color: #28a745; /* Güncelle butonu için yeşil renk */
	border: none;
	color: white;
	font-size: 16px;
	font-weight: bold;
	border-radius: 20px;
	margin-bottom: 15px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}
.team-item.selected-team {
	background-color: #333; /* Seçili takım için daha koyu renk */
}

.team-item.empty-slot {
	background-color: #555; /* Boş slotlar için varsayılan renk */
}
.update-button:hover {
	background-color: #218838; /* Hover durumu için daha koyu yeşil */
}

.buttons-container {
	display: flex;
	align-items: center;
	gap: 10px;
}

.score-rank-container {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 30px; /* Space between the points and rank */
}

.ucl-points,
.ucl-rank,
.uel-points,
.uel-rank,
.uecl-rank,
.uecl-points {
	font-weight: 500;
	font-size: 22px;
	padding: 10px 20px;
	background-color: #063089;
	color: white;
	border-radius: 8px;
	text-align: center;
}
.uel-points,
.uel-rank {
	background-color: #f05716;
}
.uecl-points,
.uecl-rank {
	background-color: #00bc12;
}

@media (max-width: 768px) {
	.clear-button p,
	.submit-button p,
	.update-button p {
		font-size: 12px; /* Daha okunabilir bir boyut */
	}
	.clear-button,
	.submit-button,
	.update-button {
		width: 100px; /* Daha makul bir genişlik */
		height: 40px; /* Yüksekliği de ayarlayabilirsiniz */
	}
}

@media (min-width: 768px) {
	.content {
		width: 75%; /* Tabletlerde %75 genişlik */
	}

	.header {
		width: 7%;
	}

	.result {
		width: 75%;
	}
	.score-rank-container {
		gap: 20px; /* Adjust gap for tablets */
	}
	.message {
		width: 75%;
	}
	.ucl-points,
	.ucl-rank,
	.uel-points,
	.uel-rank,
	.uecl-rank,
	.uecl-points {
		font-size: 20px; /* Slightly smaller font size */
		padding: 9px 18px; /* Adjust padding */
	}
	.header-buttons {
		flex-direction: row;
	}
}

/* Laptoplar ve Üstü */
@media (min-width: 1024px) {
	.content {
		width: 60%; /* Laptoplarda %60 genişlik */
	}

	.header {
		width: 60%;
	}

	.result {
		width: 60%;
	}
	.message {
		width: 60%;
	}
}
@media (max-width: 1024px) {
	.clear-button p,
	.submit-button p,
	.update-button p {
		font-size: 16px;
	}
	.clear-button,
	.submit-button,
	.update-button {
		width: 100px;
	}
}

/* Büyük Ekranlar */
@media (min-width: 1440px) {
	.content {
		width: 50%; /* Büyük ekranlarda %50 genişlik */
	}

	.header {
		width: 50%;
	}

	.result {
		width: 50%;
	}
	.message {
		width: 50%;
	}
}
@media (max-width: 480px) {
	.league-title {
		font-size: 18px;
	}

	.score-rank-container {
		flex-direction: column; /* Stack items vertically */
		gap: 0; /* Reduce gap for smaller screens */
	}

	.ucl-points,
	.ucl-rank,
	.uel-points,
	.uel-rank,
	.uecl-rank,
	.uecl-points {
		font-size: 18px; /* Smaller font size */
		padding: 8px 16px; /* Adjust padding */
		width: 100%; /* Full width for better readability */
	}

	.header-buttons {
		flex-direction: column; /* Stack buttons vertically */
		transform: none; /* Remove transform */
		gap: 5px; /* Reduce gap between buttons */
	}
	.dropdown-menu {
		width: 100%; /* Ensure dropdown is full width */
	}

	/* Add padding to the content below the header to create a gap */
}
@media (max-width: 465px) {
	.league-title {
		font-size: 17px;
	}
}
@media (max-width: 427px) {
	.league-title {
		font-size: 15px;
	}
}
@media (max-width: 389px) {
	.league-title {
		font-size: 12px;
	}
}
@media (max-width: 329px) {
	.league-title {
		font-size: 10px;
	}
}
