/* Varsayılan Stiller */
.grid-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr); /* Default olarak 4 sütun */
	gap: 8px;
	width: 100%;
}

.grid-item {
	padding: 8px 5px;
	background-color: #222;
	border-radius: 5px;
	text-align: center;
	font-size: 18px;
	font-weight: 600;
	cursor: pointer;
	min-height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color 0.3s ease, transform 0.2s ease;
}

.grid-item:hover {
	transform: scale(1.05);
	background-color: #333;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* 480px ve altı ekranlar için */
@media (max-width: 480px) {
	.grid-container {
		grid-template-columns: repeat(3, 1fr); /* 3 sütun */
		gap: 6px; /* Boşlukları küçült */
	}

	.grid-item {
		font-size: 16px; /* Yazı boyutunu küçült */
		padding: 6px 4px; /* İç boşlukları küçült */
		min-height: 50px; /* Kutu yüksekliğini küçült */
	}
}

/* 440px ve altı ekranlar için */
@media (max-width: 440px) {
	.grid-container {
		grid-template-columns: repeat(2, 1fr); /* 2 sütun */
		gap: 5px; /* Boşlukları küçült */
	}

	.grid-item {
		font-size: 16px; /* Yazı boyutunu küçült */
		padding: 5px 3px; /* İç boşlukları küçült */
		min-height: 45px; /* Kutu yüksekliğini küçült */
	}
}

/* 400px ve altı ekranlar için */
@media (max-width: 400px) {
	.grid-container {
		grid-template-columns: repeat(2, 1fr); /* 2 sütun */
		gap: 4px; /* Boşlukları küçült */
	}

	.grid-item {
		font-size: 16px; /* Daha küçük yazı boyutu */
		padding: 4px 3px; /* İç boşlukları daha da küçült */
		min-height: 40px; /* Kutu yüksekliği daha küçük */
	}
}
