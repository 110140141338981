/* Genel header yapısı */
.dashboard-logo {
	display: block; /* Ensure it's a block element */
	padding: 20px; /* Padding to provide some space around the logo */
	background-color: transparent; /* Make the background transparent */
	position: absolute; /* Use fixed positioning */
	left: 50%; /* Center horizontally */
	transform: translateX(
		-50%
	); /* Offset it by half its width to truly center it */
	z-index: 1000; /* Ensure it stays on top */
	cursor: pointer; /* Show pointer cursor on hover */
	border-radius: 50%; /* Keep the border radius */
	width: 150px; /* Width of the logo */
	height: 150px; /* Height of the logo */
}
.dashboard-button {
	display: flex; /* Ensure it's a block element */
	background-color: transparent; /* Make the background transparent */
	text-align: center;
	position: absolute; /* Use fixed positioning */
	left: 50%; /* Center horizontally */
	transform: translateX(
		-50%
	); /* Offset it by half its width to truly center it */
	z-index: 1000; /* Ensure it stays on top */
	cursor: pointer; /* Show pointer cursor on hover */
	border-radius: 50%; /* Keep the border radius */
	width: 50px; /* Width of the logo */
	height: 50px; /* Height of the logo */
	top: 200px;
	color: red;
	align-items: center;
	justify-content: center;
}
