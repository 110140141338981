.rank-main {
	flex: 100;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 20px;
	box-sizing: border-box;
	align-items: center;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative; /* hidden yerine relative kullanılmalı */
	overflow-y: hidden;
	padding-top: 275px;
}
/* UCL için mavi tonlarında bir gradient */
.rank-main.ucl {
	background: radial-gradient(
		circle,
		rgba(6, 48, 137, 0.6) 0%,
		rgba(0, 0, 0, 1) 110%
	);
}

/* Stilize edilmiş ters K harfi için */
.rank-reverse-k {
	display: inline-block;
	transform: scaleX(-1); /* Yatay eksende ters çevir */
}

/* UEL için turuncu tonlarında bir gradient */
.rank-main.uel {
	background: radial-gradient(
		circle,
		rgba(240, 87, 22, 0.6) 0%,
		rgba(0, 0, 0, 1) 110%
	);
}

/* UECL için yeşil tonlarında bir gradient */
.rank-main.uecl {
	background: radial-gradient(
		circle,
		rgba(0, 188, 18, 0.6) 0%,
		rgba(0, 0, 0, 1) 110%
	);
}

.rank-header-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #282828;
	border-radius: 10px;
	width: 80%; /* Tablonun ve yükleniyor yazısının genişliğini ayarlıyoruz */
	max-width: 1200px;
	flex-direction: column;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.rank-header-title {
	color: #ff0000;
	font-size: 28px;
	font-weight: bold;
	text-align: center;
}

.rank-header-subtitle {
	color: #c1c1c1;
	font-size: 18px;
	text-align: center;
}

.rank-league-selection {
	display: flex;
	justify-content: space-between;
	gap: 10px;
	width: 80%;
	margin-block: 15px;
}

.rank-league-title {
	color: white;
	font-size: 18px;
	font-weight: bold;
	cursor: pointer;
	padding: 8px 16px;
	border-radius: 10px;
	background-color: #444;
	transition: background-color 0.3s ease;
	flex: 1;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

/* Active states for UCL, UEL, and UECL */
.rank-league-title.active.ucl {
	background-color: #063089;
	color: white;
}

.rank-league-title.active.uel {
	background-color: #f05716;
	color: white;
}

.rank-league-title.active.uecl {
	background-color: #00bc12;
	color: white;
}

.rank-league-title:hover {
	background-color: #555;
}

.rank-table {
	width: 80%; /* Tablonun genişliğini % olarak ayarla */
	max-width: 1200px; /* Maksimum genişliği belirle */
	height: 71.9vh; /* Sabit yükseklik */
	margin-bottom: 100px;
	border-collapse: collapse;
	margin: 0 auto; /* Ortalamak için */
	overflow-y: scroll; /* Dikey kaydırma */
	overflow-x: hidden; /* Yatay kaydırmayı kapat */
	display: block; /* Tablonun tamamını kaydırılabilir yapmak için block olarak ayarla */
	border: 1px solid #ddd; /* Tablo dış kenarlara taşmasın diye border ekleyelim */
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Gölge ekleyerek görünümü daha şık hale getirelim */
}

/* Tablonun hücreleri ve başlıkları için ekstra stil */
.rank-table th,
.rank-table td {
	border: 1px solid #ddd;
	padding: 10px;
	text-align: left; /* İçeriği sola hizala */
	color: white;
}

/* Başlık hücreleri için stil */
.rank-table th {
	background-color: #444;
	font-weight: bold;
	font-size: 16px;
}

/* Hover etkisi */
.rank-table tr:hover {
	background-color: #909090;
}

/* Tablonun içerik hücreleri için stil */
.rank-table td {
	padding: 10px 15px;
	font-size: 15px;
}

/* Sıra numarası hücresi (no) ve genel sınıf için genişlik ayarı */
.rank-table th.classno,
.rank-table td.classno {
	width: 100vh; /* Dar genişlik */
	text-align: left;
}

/* Kullanıcı isimlerinin olduğu hücreler için genişlik ve stil */
.rank-table th.user,
.rank-table td.user {
	width: 70%; /* Kullanıcı ismi hücreleri daha geniş */
	font-size: 16px; /* Daha büyük font boyutu */
	text-align: center;
}

/* Puan hücresi için genişlik ayarı */
.rank-table th.points,
.rank-table td.points {
	width: 100vh; /* Dar genişlik */
	text-align: center; /* Ortaya hizala */
}

/* Highlighted row için vurgulama */
.highlighted-row {
	background-color: rgba(255, 255, 0, 0.3); /* Sarı arka plan */
}

/* Tablonun mobil görünümü için stil */
@media (max-width: 768px) {
	.rank-table {
		width: 100%; /* Mobilde genişliği %100 yap */
		height: 50vh; /* Yüksekliği küçült */
	}

	.rank-table th,
	.rank-table td {
		font-size: 14px;
		padding: 8px;
	}

	/* Sıra numarası ve puan hücreleri için daha küçük genişlik */
}

.rank-loading-background {
	background-color: #000;
	overflow-y: visible; /* Dikey kaydırma çubuğunu zorla göster */
}

.rank-loading-text {
	text-align: center;
	font-size: 18px;
	color: white;
	width: 100%; /* İçerik genişliği ile aynı */
	padding: 20px;
	box-sizing: border-box;
}

.rank-main-container {
	width: 100%; /* Tablonun ve yükleniyor yazısının genişliğini ayarlıyoruz */
	max-width: 1200px;
	margin: 0 auto;
}

.rank-content {
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%; /* Tablonun ve yükleniyor yazısının genişliğini ayarlıyoruz */
	max-width: 1200px;
	align-items: center;
	justify-content: center;
}

.goback-button {
	position: absolute;
	top: 50px;
	left: 20px;
	transform: translateY(-50%);
	width: 40px;
	height: 40px;
	background-color: #000000;
	border: none;
	border-radius: 20%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color 0.3s ease;
}

/* UCL hover rengi */
.ucl-goback-button:hover {
	background-color: #063089;
}

/* UEL hover rengi */
.uel-goback-button:hover {
	background-color: #f05716;
}

/* UECL hover rengi */
.uecl-goback-button:hover {
	background-color: #00bc12;
}

/* ... existing code ... */

/* Responsive design for phones */
@media (max-width: 480px) {
	.rank-main {
		padding-top: 275px;
	}

	.rank-header-container {
		width: 100%;
	}

	/* 	.rank-league-selection {
		flex-direction: column;
		width: 100%;
	} */

	.rank-league-title {
		font-size: 14px;
		padding: 6px;
	}

	.rank-table {
		width: 100%;
		height: 61vh;
	}

	.rank-table th,
	.rank-table td {
		font-size: 12px;
		padding: 6px;
	}

	.goback-button {
		width: 30px;
		height: 30px;
	}
}

/* Responsive design for tablets */
@media (max-width: 768px) {
	.rank-main {
		padding-top: 175px;
	}

	.rank-header-container {
		width: 100%;
		margin-top: 100px;
	}
	.rank-league-selection {
		width: 100%;
	}

	.rank-league-title {
		font-size: 16px;
		padding: 8px;
	}

	.rank-table {
		width: 100%;
		height: 62vh;
	}

	.rank-table th,
	.rank-table td {
		font-size: 14px;
		padding: 8px;
	}

	.goback-button {
		width: 45px;
		height: 45px;
	}
}

/* Adjustments for larger tablets and small laptops */
@media (min-width: 1024px) {
	.rank-content {
		width: 97%;
		margin: 0 auto; /* Center the content */
	}

	.rank-header-container {
		width: 80%;
		margin: 0 auto; /* Center the header container */
	}
}

@media (max-width: 380px) {
	.rank-table {
		height: 61vh;
	}
}
