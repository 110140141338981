.notfound-container {
	text-align: center;
	padding: 50px;
}

.notfound-container h1 {
	font-size: 72px;
	color: #ff4f4f;
}

.notfound-container p {
	font-size: 24px;
	color: #333;
}

.notfound-container button {
	background-color: #007bff;
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.notfound-container button:hover {
	background-color: #0056b3;
}
