.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 20px;
	z-index: 10000;
}

.modal-content {
	background: #1e1e1e; /* Koyu arka plan rengi */
	color: #ffffff; /* Beyaz yazı rengi */
	padding: 20px;
	border-radius: 8px;
	max-width: 600px;
	width: 100%;
	align-items: center;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.8);
}

.modal-body {
	max-height: 400px;
	overflow-y: auto;
	padding-right: 15px;
}

.modal-content h3 {
	margin-top: 20px;
	font-size: 18px;
	font-weight: bold;
	color: #ffcb57; /* Başlık rengi - sarı ton */
}

.modal-content p {
	line-height: 1.6;
	color: #c1c1c1; /* Metin rengi - açık gri ton */
}

.modal-content hr {
	border-color: #444; /* Ayraç rengi - koyu gri */
}

.close-button {
	background: #ff5c5c;
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	margin-top: 20px;
	transition: background-color 0.3s ease;
}

.close-button:hover {
	background-color: #e04848; /* Daha koyu kırmızı hover durumu */
}
