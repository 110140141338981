.comment-section {
	background-color: #282828; /* Matches your main screen style */
	color: #f5f5f5;
	padding: 20px;
	width: 100%;
}

.comments-list {
	margin-top: 20px;
}

.comment {
	border-bottom: 1px solid #444; /* Add a separator between comments */
	padding: 10px 0;
}
.comment-value {
	display: flex;
	flex: 1;
	padding: 20px;
	width: 95.5%;
	font-family: 'Roboto', sans-serif;
	border-radius: 20px;
}

.comment-value-edit {
	display: flex;
	flex: 1;
	padding: 20px;
	width: 75.5%;
	font-family: 'Roboto', sans-serif;
	border-radius: 20px;
}

.send-button,
.edit-button,
.delete-button {
	background-color: #0059ff; /* Primary button color */
	color: rgb(255, 255, 255);
	border: none;
	border-radius: 8px;
	padding: 10px;
	cursor: pointer;
	font-family: 'Roboto', sans-serif;
	margin: 5px;
}

.comment-header-text {
	margin: 15px;
}

.send-button {
	margin: 15px;
}

.edit-button {
	background-color: #ff7300; /* Primary button color */
}
.delete-button {
	background-color: #e40303; /* Darker shade on hover */
}

.send-button:hover {
	background-color: #1b6aff; /* Darker shade on hover */
}
.edit-button:hover {
	background-color: #fd821d; /* Primary button color */
}
.delete-button:hover {
	background-color: #ff0000; /* Darker shade on hover */
}

/* Responsive Design */
@media (max-width: 468px) {
	.comment-value {
		padding: 14px;
	}
}
/* Responsive Design */
@media (max-width: 608px) {
	.comment-value {
		width: 90%;
	}
}

.comment-text {
	margin-left: 10px;
	max-width: 700px; /* Set maximum width */
	word-wrap: break-word; /* Allow word wrapping */
	width: 100%; /* Full width */
	padding: 5px; /* Padding */
	overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Responsive Design for Mobile Devices */
@media (max-width: 468px) {
	.comment-text {
		max-width: 40px; /* Adjust max width for smaller screens */
		width: 60%; /* Full width */
	}
}

@media (max-width: 608px) {
	.comment-text {
		max-width: 300px; /* Further adjust max width for medium screens */
	}
}
